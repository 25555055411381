export default function figureFormatter () {
    const commaFormat = (value) => {
        if(value) {
            return new Intl.NumberFormat('en-IN', {style: 'currency', currency: 'BDT'}).format(value).replace('BDT', '').trim()
        } else {
            return 0.00
        }
    }

    return {
        commaFormat
    }
}